<ion-header mode="ios">
  <ion-row class="df pos-rel">
    <ion-label *ngFor="let item of headerList">
     <span (click)="getHeaderItems(item)" [ngClass]="{'hide': !isShowReAssign && item ==='Re-Assign', 'hr-reassign-btn-disabled' :((!enableReassign)  && (item ==='Re-Assign' || item==='Assign'))}">{{item}}</span>
    </ion-label>
  </ion-row>
</ion-header>

<ion-content>
  <ion-searchbar #searchbar mode="ios" type="text" class="search_bar" color="white" clear-icon="close-outline" search-icon="none" [(ngModel)]="searchTerm" (ionChange)="onSearch()" [alphabetOnly]="true" >
  </ion-searchbar>
  <div>
    <ion-radio-group [value]="selectedItem">
      <ion-item *ngFor="let option of loList" (click)="selectedLO(option)" [ngClass]="{'custom-radio-value': isShowReAssign && option.booleanProperty=== true}">
        <div class="template" [ngClass]="{'sc_selected_value': (option.loName === 'Reassign to Myself' ), 'disabled-reassign' : (option.loName === 'Reassign to Myself' && option.booleanProperty=== true && selectedItem !== 'My Leads') }" >{{option?.loName}}
          <div class="leadEmail" *ngIf="option?.email">{{option?.email}}</div>
        </div>
        <ion-radio slot="end" mode="ios" justify="space-between"  [value]="option?.loName"  [style]="{'--ion-radio-background': 'none', '--ion-radio-inner-background': 'none'}"
        *ngIf="!(option.loName === 'Reassign to Myself' && !newGuid)"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
  <div class="no-search-data" *ngIf="(this.showNoResult === true && loList.length === 0)">
    No User(s) found
  </div>
</ion-content>


