<ion-content #scrollContent (ionScroll)="handleScroll($event)">
  <ion-grid fixed="true">
    <ion-list *ngIf="!selectedUserType.unassigned">
      <div *ngFor="let lead of leadsService.getLOLeadsList" class="item-list">
        <div>
          <ion-item-sliding #slidingItem>
            <ion-item class="pos-rel" (click)="goToLeadDetailsPage(lead.lead_guid, lead?.borrower?.first_name)">
              <ion-row class="row-mg">
                <ion-label style="padding-bottom: 2px">
                  <p>
                <span class="label-name"
                >{{ lead?.borrower?.first_name }} {{ lead?.borrower?.last_name }}</span
                >
                    <span *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'">
                  <img src="{{(deviceMode) ? lead?.contact_details?.auto_call ? '../../../assets/icons/call-attend-dark.svg' : '../../../assets/icons/no-phone-dark.svg' : lead?.contact_details?.auto_call ? '../../../assets/icons/call-attend.svg' : '../../../assets/icons/no-phone.svg'}}"
                       class="pl-12" alt="" />
                  <img src="{{(deviceMode) ? lead?.contact_details?.auto_sms ? '../../../assets/icons/sms-sent-dark.svg' : '../../../assets/icons/text-not-sent-dark.svg' : lead?.contact_details?.auto_sms ? '../../../assets/icons/sms-sent.svg' : '../../../assets/icons/text-not-sent.svg'}}"
                       class="pl-10" alt="" />
                  <img src="{{(deviceMode) ? lead?.contact_details?.auto_email ? '../../../assets/icons/mail-sent-dark.svg' : '../../../assets/icons/mail_not_sent-dark.svg' : lead?.contact_details?.auto_email ? '../../../assets/icons/mail-sent.svg' : '../../../assets/icons/mail_not_sent.svg'}}"
                       class="pl-10 pb-2 h-14" alt=""/>
                </span>
                    <span class="ml-12" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason === 'Request Do Not Call'">
                       <img
                         src='../../../assets/icons/disabled-call.svg'
                         class="pl-12" alt="" />
                        <img
                          src='../../../assets/icons/disabled-sms.svg'
                          class="pl-12" alt="" />
                        <img style="margin: 3px;padding-left: 7px;"
                             src='../../../assets/icons/disabled-email.svg'
                             class="pl-12" alt="" />
                     </span>
                  </p>
                  <p style="display: inline-block">
                <span class="label-phone-number">{{
                  formatPhoneNumber(lead.borrower.phone_number)
                  }}</span>
                  </p>
                  <ion-col class="col-height">
                    <ion-label class="text-heading mb-4"><span
                       style="padding-right: 15px;">Date Created:</span><span
                      class="label-last-attempted ml-40">{{dateTimeConversion(lead?.lead_created_date)}}</span>
                    </ion-label>
                    <div *ngIf="lead?.contact_details?.method_of_contact">
                      <ion-label class="text-heading mb-4"><span *ngIf="lead?.status?.code !== 'CONTACTED'">Last Attempted:</span><span
                        *ngIf="lead?.status?.code === 'CONTACTED'" style="padding-right: 12px;">Contacted on:</span><span
                        class="label-last-attempted ml-40">{{ dateTimeConversion(lead?.contact_details?.last_attempted)}}</span>
                      </ion-label>
                      <ion-label class="text-heading mb-4" [ngClass]="{'mb-6': !lead?.lead_source}"><span>Method of Contact:</span><span
                        class="label-last-attempted ml-21">{{ getFormattedMethodOfContact(lead) }}</span></ion-label>
                    </div>
                    <ion-label *ngIf="lead?.lead_source" class="text-heading mb-4"><span>Lead Source:</span><span
                      class="label-last-attempted ml-60">{{lead?.lead_source?.lead_source_name}}</span></ion-label>
                    <ion-label *ngIf="lead?.agent?.first_name" class="text-heading mb-4"><span>Agent Name:</span><span
                      class="label-last-attempted ml-60">{{lead?.agent?.first_name}}</span></ion-label>
                    <!-- <ion-label class="text-heading mb-8">No. of Attempts:<span class="label-last-attempted ml-38">2</span></ion-label> -->
                    <ion-label id="label-notes" *ngIf="lead?.note?.content">{{lead?.note?.content}}</ion-label>
                  </ion-col>
                  <p>
                    <span class="status"
                          ngClass="{{getStatusChipLevelClass(lead.status.level)}}">{{lead?.status?.value}}</span>
                    <span class="status-desc">{{
                      lead?.status?.description
                      }}</span>
                  </p>
                </ion-label>
              </ion-row>
              <ion-checkbox [checked]="lead?.isSelectAll" class="pos-abs" *ngIf="isEnableCheckBox" mode="md" slot="end"
                            (click)="$event.stopPropagation();" (ionChange)="reAssign(lead, $event)"></ion-checkbox>
            </ion-item>

            <ion-item-options (click)="share(slidingItem)">
              <ion-item-option class="sliding bg">
                <div class="circle call-padd pos-rel" *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'">
                  <img src="../../../assets/icons/call.svg" class="icon-pos" alt=""
                       (click)="manualCalling($event,lead?.lead_guid)" />
                </div>
                <div class="circle call-padd pos-rel bg-slide-circle-disabled" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason === 'Request Do Not Call'">
                  <img src="../../../assets/icons/opt-out-call.svg" class="icon-pos" alt=""
                       (click)="toasterServie.archiveLeadTstrMsg('Call')" />
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle text-padd pos-rel"  *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'"
                     [ngClass]="{'bg-slide-circle-disabled': lead?.is_opt_out_for_sms }"
                     style="left: 5px !important">
                  <img src="../../../assets/icons/text.svg" *ngIf="!lead?.is_opt_out_for_sms" class="icon-pos-2" alt=""
                       (click)="goToChat(lead)" />
                  <img src="../../../assets/icons/opt-out-sms-pipeline.svg" style="top: 8px;left: 8px;"
                       *ngIf="lead?.is_opt_out_for_sms"
                       class="icon-pos-2" alt=""
                       (click)="toasterServie.optOutToasterMsg(lead?.borrower?.first_name, lead?.borrower?.last_name,'','message')" />
                </div>
                <div class="circle text-padd pos-rel bg-slide-circle-disabled" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD'  && lead?.status?.reason === 'Request Do Not Call'" style="left: 5px !important">
                  <img src="../../../assets/icons/opt-out-sms-pipeline.svg" style="top: 8px;left: 8px;"
                       class="icon-pos-2" alt=""
                       (click)="toasterServie.archiveLeadTstrMsg('SMS')" />
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle email-padd pos-rel"  *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'" style="left: 1px !important;" [ngClass]="{'dis-clr': lead?.is_opt_out_for_email}"
                     (click)="composeMail(lead, $event)">
                  <img src="../../../assets/icons/email.svg" class="icon-pos-2" alt=""/>
                  <img *ngIf="lead?.is_opt_out_for_email" class="dis-icon" src="../../../assets/icons/disable.svg"/>
                </div>
                <div class="circle email-padd pos-rel dis-clr" style="left: 1px !important" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD'  && lead?.status?.reason === 'Request Do Not Call'">
                  <img src="../../../assets/icons/email.svg" class="icon-pos-2" alt=""
                       (click)="toasterServie.archiveLeadTstrMsg('Email')" />
                  <img class="dis-icon" src="../../../assets/icons/disable.svg"/>
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle notes-padd pos-rel" style="left: -3px !important;"
                     (click)="openModal(lead)">
                  <img src="../../../assets/icons/notes.svg" class="icon-pos-3" alt="" />
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle currency-padd pos-rel" style="right: 14px !important; left: -6px !important;"
                     (click)="gotoGetQuote(lead.lead_guid)"
                >
                  <img src="../../../assets/icons/currency.svg" class="icon-pos-4" alt="" />
                </div>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </div>
    </ion-list>

    <!-- Triggers when the user type is unassigned leads -->
    <div class="unassigned_leads">
    <ion-list *ngIf="selectedUserType.unassigned">
      <ion-item *ngFor="let item of leadsService.getLOLeadsList">
        <ion-label class="u_container">
          <p>{{item?.first_name}} {{item?.last_name}}</p>
          <span id="fw">{{formatPhoneNumber(item?.phone_number)}}</span>
          <span id="fw">{{item?.email}}</span>
          <span>Previously Assigned: <span id="prev">{{item?.assigned_loan_officer?.first_name}} {{item?.assigned_loan_officer?.last_name}}</span></span>
        </ion-label>
      <ion-checkbox [checked]="item?.isSelectAll" class="pos-abs" mode="md" slot="end"
                          (click)="$event.stopPropagation();" (ionChange)="reAssign(item, $event)"></ion-checkbox>
      </ion-item>
      </ion-list>
    </div>
    <ion-infinite-scroll
      (ionInfinite)="doInfinite($event)"
      position="bottom"
      threshold="0"
      [disabled]="hasLoadedAllLeads"
    >
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

  </ion-grid>
  <div *ngIf="!leadsService.getLOLeadsList.length && this.isLoadingCheck === true" class="no-leads-list">No leads
    currently assigned to your pipeline
  </div>
</ion-content>

<div *ngIf="isEnableCheckBox && leadsService.getLOLeadsList?.length"
     [ngClass]="{'reAssign_Btn': isSelected === true }">
  <button (click)="goToReAssign()" *ngIf="!selectedUserType.unassigned">Re-Assign</button>
  <button *ngIf="selectedUserType.unassigned" (click)="goToAssign()">Assign</button>
  <button (click)="cancelSelection()">Cancel</button>
</div>

<div *ngIf="isDropdownSelected" [ngClass]="{'sltLead_Btn': isDropdownSelected === true}">
  <div class="sel-btn">
    <ng-container *ngFor="let button of buttons">
      <span class="btn" *ngIf="button.visible" [ngClass]="{'active-btn': selectedUserType.code === button.code}" (click)="gotoMyLeads(button.code)">
        {{button.label}}</span>
    </ng-container>
  </div>
  <button class="cncl-btn" (click)="cancelClicked()">Cancel</button>
</div>
<div *ngIf="isSortSelected" class='sltLead_Btn'>
  <div class="sel-btn">
    <ng-container *ngFor="let button of sortOptions">
      <span class="btn" *ngIf="button.visible" [ngClass]="{'active-btn': selectedSortOption === button.code}" (click)="changeSortOption(button)">
        {{ button.label }}
        <img class="btn-img" [src]="getSortIcon(button)" alt="">
      </span>
    </ng-container>
  </div>
  <button class="cncl-btn" (click)="cancelClicked()">Cancel</button>
</div>
