import { AfterViewChecked, Component, OnDestroy, ViewChild } from '@angular/core';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { ListPipelineComponent, SearchComponent } from 'src/app/components';
import { LeadsService } from '../../services/leads.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NotesService } from 'src/app/services/notes.service';
import { sidebarConfig } from 'src/app/models/detail.model';
import { getEditCustomerInfoPage, getLeadAddModel } from 'src/app/config/leadEditForm';
import { SidebarService } from 'src/app/services/sidebar.service';
import { LeadAddUI, emptyObj } from 'src/app/models/detailUI.model';
import { NotificationService } from 'src/app/services/notification.service';
import { AlertService } from 'src/app/services/alert.service';
import { Subscription, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loader.service';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { selectedUserType } from 'src/app/models/leads.model';
import { filterIconModel, filterIcons, sortIconModel, sortIcons } from 'src/app/models/sort.model';
import { filterComponent } from 'src/app/components/filter-component/filter-component';
import { FilterService } from 'src/app/services/filter.service';
import { isEmpty } from 'lodash';
import { HomeBtnService } from 'src/app/services/home-btn.service';


@Component({
    selector: 'app-pipeline',
    templateUrl: './pipeline.component.html',
    styleUrls: ['./pipeline.component.scss'],
})
export class PipelineComponent implements OnDestroy {
   @ViewChild(ListPipelineComponent) listPipelineComponent: ListPipelineComponent;
    searchTerm: any;
    loanOfficerGuidId = '';
    sidebarConfig: sidebarConfig;
    isEnableCheckBox = false;
    appConfig: any;
    selectAll = false;
    showLODropdown: any = false;
    userRoles: any[] = ['branch-manager', 'regional-manager', 'divisional-manager'];
    selectedLeadInfo: any;
    selectLimit = true;
    isSelectAll: any;
    deviceMode: any;
    showDrop: boolean = false;
    isDropdownSelected: boolean;
    isManualLeadCreationEnabled: boolean = true;
    appConfigSub: any;
    selectedUserType: selectedUserType;
    isSortSelected: boolean = false;
    sortIcons: sortIconModel = sortIcons;
    filterIcons: filterIconModel = filterIcons
    private subscriptions: Subscription[] = [];
    loName: string;
    modalData:string = 'None';
    selectedlength:number = 0;
    filterText: string;
  routeParams: string;

    constructor(
        private modalCtrl: ModalController,
        public leadsService: LeadsService,
        private router: Router,
        private route: ActivatedRoute,
        public menuController: MenuController,
        public noteSvc: NotesService,
        private SidebarService: SidebarService,
        public notificationService: NotificationService,
        public alertSvc: AlertService,
        public alerCtrl: AlertController,
        public authSvc: AuthService,
        public loadingSvc: LoadingService,
        public loService: LoServiceService,
        private modalctrl:ModalController,
        public filterService: FilterService,
        public homeBtnService: HomeBtnService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('/pipeline')) {
                    this.init();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptions)
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
    }

    ngOnInit(): void {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        const isBatchSelect = sessionStorage.getItem('batchSelect');

        this.subscriptions.push(
            this.authSvc.appConfigData.subscribe((appConfig) => {
                this.isManualLeadCreationEnabled = appConfig?.leadCreation?.enabled;
            })
        );

        this.subscriptions.push(
            this.loService.selectedUserType.subscribe((selectedUserType: selectedUserType) => {
                this.selectedUserType = selectedUserType;
            })
        );

        this.loService.loName.subscribe((res: any)=>{
            if(res && res.loName)
                this.loName = res.loName;
        })

        this.selectedlength = this.filterService.selectedFiltersLength;
        if (isBatchSelect) {
            this.isSelectAll = JSON.parse(isBatchSelect);
            if (this.isSelectAll === true) {
                this.selectAll = true;
            }
        }
        this.init();
     }

    ionViewWillLeave(){
        this.homeBtnService.show();
     }

    ionViewWillEnter(){
      const storedSelectedLeadInfo = sessionStorage.getItem('selectedLeadInfo');
      this.isEnableCheckBox = false;
      if (storedSelectedLeadInfo) {
        this.selectedLeadInfo = JSON.parse(storedSelectedLeadInfo);
        if (this.selectedLeadInfo.length > 0) {
            this.isEnableCheckBox = true;
        }
      }

      this.homeBtnService.hide();
    }

    init() {
        this.menuController.swipeGesture(false);
        this.sidebarConfig = getLeadAddModel(emptyObj, this.leadsService);
        this.getUserData();
    }

    reAssign() {
        this.loadingSvc.show();
        const payload = JSON.parse(sessionStorage.getItem('reAssignleadsPayload') as any);
        payload.isSelectedAll = this.selectLimit;
        this.loadingSvc.hide();
        this.router.navigate(['direct-reports/pipeline', `${this.routeParams}`]);
    }

    toggleDropdown() {
        this.isDropdownSelected = !this.isDropdownSelected;
        this.cancelSelectedLead();
    }

    get SortIcons() {
        if (this.leadsService.selectedOrder === 'ASC') {
            return this.sortIcons.pipelineAsecIcon;
        } else if (this.leadsService.selectedOrder === 'DESC') {
            return this.sortIcons.pipelineDescIcon;
        } else {
            return this.sortIcons.sortIcon;
        }
    }

    get FilterIcons(){
      const icon = this.selectedlength>0?this.filterIcons.filtered: this.filterIcons.unfiltered;
      return icon;
    }

    toggleSort() {
        this.isSortSelected = !this.isSortSelected;
    }

    cancelClicked() {
        this.isDropdownSelected = false;
        this.isSortSelected = false;
    }

    getUserData() {
        this.authSvc.userData.subscribe({
            next: (res: any) => {
                res ? res : (res = JSON.parse(sessionStorage.getItem('roles') as any));
                res?.some((rl: any) => this.userRoles?.includes(rl))
                    ? (this.showLODropdown = true)
                    : '';
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    async openSearch() {
        const data = {
            loanOfficerGuidId: this.loanOfficerGuidId,
        };
        const url = `search/${this.loanOfficerGuidId}/PIPELINE`;
        this.router.navigate([url, data]);
    }

    getLoanOfficer(event: any) {
        this.loanOfficerGuidId = event?.loan_officer_guid;
        this.notificationService.requestPermission(this.loanOfficerGuidId);
    }

    openAddLead(): void {
        this.getLeadSourceOptions();
        // sessionStorage.removeItem('unSavedLead');
        sessionStorage.removeItem('leadModelValues');
        sessionStorage.removeItem('quoteDetails');
        sessionStorage.removeItem('editLead');
        sessionStorage.removeItem('selectedLeadSource');
        this.sidebarConfig.title = 'Add Lead';
        this.sidebarConfig.sidebarID = 'addLead';
        this.SidebarService.isLeadSourceAdded = true;
        this.sidebarConfig = getLeadAddModel(emptyObj, this.leadsService);
        this.SidebarService._sidebarConfig.next(this.sidebarConfig);
        this.SidebarService._sidebarVisible.next(true);
    }

    getDropdownOptions(dropdownKey: string) {
        return this.leadsService.getDropDown(dropdownKey);
    }

    selectLead() {
        this.isEnableCheckBox = !this.isEnableCheckBox;
    }

    SelectAll() {
        const data: any = {
            title: '',
            message:
                'Please note that a maximum of the initial 25 leads will be selected for reassignment. Do you want to continue?',
        };
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
            this.cancelSelectedLead();
        }
        sessionStorage.setItem('batchSelect', this.selectAll.toString());
        this.selectAll
            ? this.alertSvc.presentAlert(
                  this.alerCtrl,
                  data,
                  () => {
                  this.routeParams = this.selectedUserType.unassigned ? 'assign':'reAssign'
                  this.reAssign();
                  },
                  () => {
                      this.selectLimit = false;
                  }
              )
            : null;
    }

    cancelSelectedLead() {
        this.isEnableCheckBox = false;
        this.selectAll = false;
        sessionStorage.removeItem('selectedLeadInfo');
        sessionStorage.removeItem('batchSelect');
        sessionStorage.removeItem('reAssignleadsPayload');
    }

    cancelClickEvent() {
        this.cancelSelectedLead();
        this.cancelClicked();
    }

    getLeadSourceOptions() {
        const leadSourceOptions = this.leadsService.leadSrcDropdown(true);
        const stateOptions = this.getDropdownOptions('STATE');
        forkJoin([leadSourceOptions, stateOptions]).subscribe(
            ([leadSourceRes, stateRes]: any) => {
                if (leadSourceRes?.data) {
                    LeadAddUI.forEach((field: any) => {
                        field.fields.forEach(
                            (element: { fieldKey: string; type: string; options: any }) => {
                                if (
                                    element.type == 'dropDown' &&
                                    element.fieldKey == 'lead_source'
                                ) {
                                    element.options = leadSourceRes?.data;
                                } else if (
                                    element.type == 'dropDown' &&
                                    element.fieldKey == 'state'
                                ) {
                                    element.options = stateRes.data;
                                }
                            }
                        );
                    });
                }
            },
            (err) => {
                console.log('Issue with dropdown values loading defaults');
            }
        );
    }

    gotoMyLeads(code: string) {
      if (this.selectedUserType.code === code) {
              const isReporteeList = code == 'reportees';
              const route = isReporteeList ? 'report' : 'proxy';
              const params = isReporteeList
                  ? [{ isReporteeList: true }]
                  : !isReporteeList
                  ? [{ isProxyList: true }]
                  : [];
              this.router.navigate([`direct-reports/pipeline/${route}`, ...params]);
          }
      }

  async openModal() {
    const modal = await this.modalctrl.create({
      component: filterComponent,
      componentProps: {
        filterProps: {
          checkboxValues: this.filterService.pipelineFilter,
          groupAsAccordion: true,
          headerTitle: 'Pipeline Filter'
        }
      },
      cssClass: 'custom-modal'
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    this.filterService.selectedPipelineFilters.status = '';
    this.filterService.selectedPipelineFilters.agentName = '';
   if ((role === 'filter' || role === 'cancel') && !isEmpty(data.selectedValue)) {
       const agentNames = data.selectedValue
           .filter((item: any) => item.category === 'Agent Name')
           .map((item: any) => item.value.replace(/ /g, '_'));

       const statuses = data.selectedValue
           .filter((item: any) => item.category === 'Status')
           .map((item: any) => item.value.toUpperCase().replace(/ /g, '_'));

       if (agentNames.length > 0) {
           this.filterService.selectedPipelineFilters.agentName = agentNames.join(',');
       }
       if (statuses.length > 0) {
           this.filterService.selectedPipelineFilters.status = statuses.join(',');
       }

       this.modalData = [...agentNames, ...statuses].join(',');
   } else {
       this.modalData = 'None';
   }
    this.filterText = this.modalData !== 'None' ? this.modalData : '';
    this.selectedlength = data.selectedValue.length ? data.selectedValue.length : '';
    this.filterService.selectedFiltersLength = this.selectedlength;
    if (this.listPipelineComponent) {
      this.listPipelineComponent.getLOList();
    }
  }
}
