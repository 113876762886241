import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loader.service';
import { LeadsService } from '../services/leads.service';
import { LoServiceService } from '../services/lo-service.service';
import { settingsItems } from '../models/app.model';

@Injectable()
export class UserGuard implements CanActivate {
    token: any;
    loPhoneNumber: any;
    constructor(
        private authService: AuthService,
        public route: ActivatedRoute,
        public router: Router,
        public loadingSvc: LoadingService,
        public leadsService: LeadsService,
        private loService: LoServiceService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.loStatus(route, state);
    }

    async loStatus(route: any, state: any) {
        this.token = sessionStorage.getItem('authorizationCode');
        const loStatusCode: any = sessionStorage.getItem('LoStatusCode');

        if (loStatusCode == 200) {
            return loStatusCode;
        } else {
            if (this.token) {
                this.loadingSvc.show();
                Promise.all([
                    this.authService.getLoPhoneNumber(this.token),
                    this.authService.getUserData(this.token),
                ])
                    .then(([loPhoneNumberResponse, response]: any) => {
                        this.loadingSvc.hide();
                        this.loPhoneNumber = loPhoneNumberResponse?.data?.phone;
                        this.loStatusCheck(response?.code);
                        sessionStorage.setItem('LoStatusCode', response?.code);
                        sessionStorage.setItem('roles', JSON.stringify(response.data.roles));
                        sessionStorage.setItem('licensedStates', JSON.stringify(response.data.licensedStates));
                        sessionStorage.setItem('loPhoneNumber', this.loPhoneNumber);
                        sessionStorage.setItem(
                            'hasPricingCredentials',
                            response.data.hasPricingCredentials
                        );
                        sessionStorage.setItem(
                            'loggedInUserDetails',
                            JSON.stringify(response.data)
                        );
                        this.loService.loggedInUserDetail = response.data;
                        this.authService.userData.next(response?.data?.roles);
                        if (this.loPhoneNumber?.length < 10 || this.loPhoneNumber === '') {
                            this.router.navigate(['/settings'], {
                                state: {
                                    settings: settingsItems.find((SettingsItem)=>SettingsItem.code=="mobile"),
                                },
                            });
                            sessionStorage.setItem('hideCancel', 'false');
                            sessionStorage.setItem('path', 'false');
                        }
                        this.authService.LoPhoneNumber.next(this.loPhoneNumber);
                        return true;
                    })
                    .catch((err) => {
                        this.sendLoginAttemptlog();
                        this.authService.removeTokens();
                        this.loadingSvc.hide();
                        this.goToForbiddenScreen();
                        return false;
                    });
            } else {
                this.router.navigate(['/login']);
            }
        }
    }

    loStatusCheck(code: any) {
        switch (code) {
            case 200:
                // login
                break;
            case 403:
                this.sendLoginAttemptlog();
                this.goToForbiddenScreen();
                break;
        }
    }

    goToForbiddenScreen() {
        this.router.navigate(['forbidden']);
    }

    sendLoginAttemptlog() {
        this.authService.forbiddenLog(this.token).then((response) => {
            console.log(response);
        });
    }
}
