import { Component, HostListener, Input } from '@angular/core';
import { Subject, interval } from 'rxjs';
import { LoadingService } from './services/loader.service';
import { ActionSheetController, AlertController, MenuController, ModalController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { HttpService } from './services/http.service';
import { environment } from '../environments/environment';
import { TokenService } from './services/token.service';
import { ApiURL } from './config/api-url';
import { ToasterService } from './services/toaster.service';
import { sidebarConfig } from './models/detail.model';
import { SidebarService } from 'src/app/services/sidebar.service';
import { UserlogService } from './services/userlog.service';
import { LoServiceService } from './services/lo-service.service';
import { NotesService } from './services/notes.service';
import { HomeBtnService } from './services/home-btn.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    isLoading: Subject<boolean> = this.loadingServivce.isLoading;
    isHomeButton: Subject<boolean> = this.homeBtnService.isHomeButton;
    timeLeft = 300000;
    interval: any;
    showSesssionPop = false;
    getToken = true;
    alertPresent = false;
    @Input() sidebarConfig: sidebarConfig;
    sessionId : any;
    constructor(
        public loadingServivce: LoadingService,
        private platform: Platform,
        private authService: AuthService,
        private router: Router,
        private api: HttpService,
        private tokenService: TokenService,
        public alertCtrl: AlertController,
        private toastSvc: ToasterService,
        public menuCtrl: MenuController,
        private SidebarService: SidebarService,
        private actionSheetController: ActionSheetController,
        private modalController: ModalController,
        private userLog: UserlogService,
        private loService: LoServiceService,
        public noteSvc: NotesService,
        public homeBtnService: HomeBtnService
    ) {
        this.initializeApp();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        this.menuCtrl.close();
    }

    initializeApp(): void {
        this.platform.ready().then(() => {
            this.processSessionExpiration();
        });
    }

    message:any = null;

  ngOnInit(): void {
    sessionStorage.setItem('deviceMode', JSON.stringify((window?.matchMedia("(prefers-color-scheme: dark)"))?.matches));
      if (window.location.href.includes("chat?")) {
          var url_string = window.location.href;
          var url = new URL(url_string);
          let bParamValue: any = url.searchParams.get('conversationId');
          let bValue = bParamValue.substring(0, bParamValue.indexOf('/summary'));
          sessionStorage.setItem('lead_guid', JSON.stringify(url.searchParams.get('leadId')));
          sessionStorage.setItem('conversation_id', JSON.stringify(bValue));
          const leadGuid = JSON.parse(sessionStorage.getItem('lead_guid') as any);
          const conversationId = JSON.parse(sessionStorage.getItem('conversation_id') as any);
          if (leadGuid && conversationId) {
              const url = `/chat/${leadGuid}/${conversationId}/SUMMARY`;
              this.router.navigate([url]);
          }
      }
      this.authService.getAppConfig();

      const loggedInUserDetail = JSON.parse(sessionStorage.getItem('loggedInUserDetails') as any);
      this.loService.loggedInUserDetail = loggedInUserDetail;
  }


    processSessionExpiration(): void {
        const loginPath = '/login';
        if (location.href.toLowerCase().indexOf(loginPath) === -1) {
            const EncompassRefreshTokenAPICall = Number(
                `${environment.EncompassRefreshTokenAPICall}`
            );
            const EncompassAccessTokenTimeOut = Number(
                `${environment.EncompassAccessTokenTimeOut}`
            );

            interval(EncompassRefreshTokenAPICall).subscribe((x) => {                
                if (this.getToken) {
                    if (location.href.toLowerCase().indexOf(loginPath) === -1) {
                        this.api.get(ApiURL.Refresh).subscribe((result) => {                            
                            this.tokenService.setAuthorizationCode(result.data);
                            sessionStorage.setItem('LoStatusCode', '');
                            this.authService.getUserData();
                          });
                    }
                }
            });
        }
    }

     logout(isSessionExpire?: boolean) {
        if (isSessionExpire) {
            this.sessionId = sessionStorage.getItem('sessionID');
            this.userLog.logoutLog(this.sessionId);
            this.dismissActionSheet();
            this.closeModal();
            this.dismissAlert();
            this.SidebarService._sidebarVisible.next(false);
            sessionStorage.removeItem('loggedInUserDetails');
            this.toastSvc.presentToast('Your session has expired.', 'info-toast');
            this.authService.logOutAzure();
           
        }
        this.authService.removeTokens();
      }

      async dismissActionSheet() {
        const element = await this.actionSheetController.getTop();
        if (element) {
          element.dismiss();
        }
      }

      async closeModal() {
        const modal = await this.modalController.getTop();
        if (modal) {
          await modal.dismiss();
        }
      }

      async dismissAlert() {
        const alert = await this.alertCtrl.getTop();
        if (alert) {
          await alert.dismiss();
        }
      }
}
