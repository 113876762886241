const messages = {
    confirmAlertMsg: 'Any unsaved data will be lost. Do you want to continue?',
    newNoteToastMsg: 'Note saved to Activities successfully.',
    newNoteToastMsgWithEmail: 'Note saved to Activities successfully and an email is sent to the agent.',
    newNoteOnActivityTab: 'Notes added successfully.',
    newNoteOnActivityTabWithEmail: 'Note(s) added successfully and an email is sent to the agent.',
    emailNotAvailable: 'Agent email is not available.',
    previousNote: 'Notes updated successfully.',
    previousNoteWithEmail: 'Note(s) updated successfully and an email is sent to the agent',
    emailMsg: 'Any modifications made to the email will be discarded. Do you wish to proceed?',
    subjectMsg: `Email cannot be blank. The subject line cannot be left empty.`,
    emptyMailMsg: `Email cannot be blank. The subject line and email message cannot be left empty.`,
    getQuotesMsg: `There is an issue with your Optimal Blue set-up.  Please email <span class="email-address">BEEM@bayeq.com</span> to resolve this issue.`,
    getInvalidStateCountyMsg:
        'Quote cannot be generated as the State or County Value is invalid for the selected product',
    getQuoteProductsMsg: `An error occurred while retrieving the products. Please try again after sometime.`,
    productExpriredMsg: 'Products search results have expired. Please search for products again.',
    noFeeMsg: `Quote cannot be generated as the fee types for the selected product have not been fetched from the OB.`,
    noImpoundMsg: `Quote cannot be generated as the selected Subject Property State has not been configured in BEEM. Please email <span class="email-address">BEEM@bayeq.com</span> to resolve this issue.`,
    noFeeandImpoundMsg: `Quote cannot be generated as the fee types has not been fetched from the OB and impounds for the selected subject property state have not been configured in BEEM. Please email <span class="email-address">BEEM@bayeq.com</span> to resolve this issue.`,
    //Invite to apply
    unavailableURL: `Invite to apply could not be processed due to the unavailability of the URL.`,
    invalidURL: `Invite to apply could not be processed due to Invalid URL.`,
    invalidContact: `Invite to apply could not be processed due to Invalid email id and phone number.`,
    invalidLoPhone: `To proceed, please update your mobile phone number.`,
    disableSave: `Mandatory information is missing.`,
    deleteCustomLeadSrc: `Custom lead source has been deleted successfully.`,
    confirmationMsgCustomLead: `Are you sure you want to delete the custom lead source?`,
    customLeadUpdatedMsg: `Custom lead source has been updated successfully.`,
    customLeadSavedMsg: `Custom lead source has been created successfully.`,
    deleteCustomSubject: `Are you sure you want to delete the custom subject?`,
    deletedCustomSubj: `Custom subject has been deleted successfully.`,
    customSubjectCreated: `Custom subject has been created successfully.`,
    customSubjectUpdated: `Custom subject has been updated successfully.`,
    maxCustomSubjectLimit: `Custom subject line has exceeded the maximum limit of 20.`,
    maxCustomCharacterLimit: `Custom subject line has reached the maximum character limit.`,
    // Campaign
    confirmToActive : 'Campaign will be activated to this lead. Do you want to continue?',
    CampaignWillNotBeActivated: 'The campaign will only be activated when selecting the "Attempting to Contact" status. You have opted for a different status where the campaign is not relevant. Do you wish to proceed?',
    archiveLead: (leadName: string)=>{ return `Do you really want to archive ${leadName}?`},
    campaignToApply: (leadName: string) =>{ return  `Do you really want to apply a campaign to ${leadName}`},
    leadStatusUpdated: (prevStsValue: string, newStsValue: string) => {
        return `Lead status updated from ${prevStsValue} to ${newStsValue} successfully.`;
    },
    leadStatusWithCampaign: (newStsValue: string) => {
        return `Lead status updated to ${newStsValue}, and the campaign added successfully.`;
    },

    // Proxy user
    quotesDenied: `Quote cannot be generated as you do not have necessary permission.`,

    //Proxy State
    quotesDeniedForState:  `Quote cannot be generated as you do not have the valid license for the selected state.`,

    //home navigation
    confirmHomeNavigation: `Navigate to Home Page?`
};

const labels = {
    // Multi user selection
    pipelineReportee: 'My Reportee',
    pipelineProxyUser: 'Proxy User',
    userPageReportee: 'My Reportee(s)',
    userPageProxy: "User's List",
};

const toasterMsg = {
    // User Setttings
    phoneNumberUpdate: 'Mobile phone details updated successfully.',
    agentSettings: 'Agent Settings updated successfully.',
    proxySettings: 'Proxy User Settings updated successfully',
    comSettings: 'Communication settings saved successfully.',
    //Customer info
    leadUpdated: 'Customer Info. has been updated.',
    leadCreated: 'New lead has been created successfully.',

    // Task Msg
    taskReopen: 'Task has been reopened.',
    taskCompleted: 'Task has been completed successfully.',
    taskCreatedSus: 'Task has been created successfully.',
    taskUpdate: 'Task has been updated.',
    emailToast: 'Email has been sent successfully.',
    errorToast: 'Please specify at least one recipient.',
    validEmailId: 'Please provide a valid email address',
    updateLeadStatus: 'Lead status updated successfully.',
    emailError: 'Email delivery was unsuccessful.',

    //Manual call
    callInitialized: 'Call is initiated',

    //Invite to apply
    inviteMessage: 'Invite to Apply method has been sent successfully.',
    invalidEmail: `Invite to apply could not be processed due to Invalid email id.`,
    invalidPhone: `Invite to apply could not be processed due to Invalid phone number.`,
    emailSuccessInvalidNum: `The invite to apply was processed successfully through Email. The attempt to send an SMS was unsuccessful due to an invalid phone number.`,
    numSuccessInvalidEmail: `The invite to apply was processed successfully through SMS. The attempt to send an email was unsuccessful due to an invalid email address.`,
    emailFailure: `An attempt to send an email was unsuccessful.`,
    noMoreRates: `The chosen product doesn't have additional pricing options.`,
    smsSuccessEmailDown: `The invite to apply was processed successfully through SMS. An attempt to send the email was unsuccessful.`,
    emailSuccessSmsDown: `The invite to apply was processed successfully through Email. An attempt to send the SMS was unsuccessful.`,

    //Agent Settings
    excludedAgents: `Automated Communication(s) will not be sent to the selected agents.`,
    noAgentsExcluded: `No agents selected.`,

    //Proxy Settings
    includedUsers: `Proxy User Settings updated successfully`,
    noUsersIncluded: `Proxy User Settings updated successfully`,
    toggleDisabled: `No proxy user(s) assigned.`,

    //filter
    filterApplied: `Filter applied successfully.`
};

export { messages, toasterMsg, labels };
